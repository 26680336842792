import Image from "next/image";
import CountDown from "./CountDonw";

export default function CommingSoon() {
  return (
    <>
      <div className="rbt-countdown-area rbt-maintenance-area rbt-section-gap vh-100 d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12">
              <div className="inner">
                <div className="section-title text-center">
                  <Image src="/novos/img/logo-colorida.png" width={300} height={152} alt="Aulé" loading="eager" />
                  <p className="description has-medium-font-size mt--20 mb--0 color-black opacity-7">O reforço que seu filho precisa para aprender</p>
                </div>
                <div className="countdown-style-1 mt--50 justify-content-center">
                  <div className="countdown justify-content-center" data-date="2024-11-07">
                    <CountDown targetDate="2024-11-07" targetTime="20:00:00" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CommingSoon.displayName = "Empty";
